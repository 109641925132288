
require('./bootstrap');
require('./third_party/coolclock');
require('es6-promise/auto');

import Vue from 'vue';
import Login from "./components/Login/Login";

Vue.component('login', Login)
new Vue(
    {
        el: '.acclaro-vue-content',
        render: (createElement) => {
            return createElement(Login)
        },
    }
);


Vue.config.devtools = process.env.APP_ENV !== 'local';
