<template>
    <div class="login-content-container">
        <div class="separator">
            <img src="/images/login/acclaro-logo.png" id="login-logo" alt="logo"/>
        </div>
        <div><h2 id="login-header">Client Sign-in</h2></div>
        <div v-if="message !== ''" class="message" v-html="message"></div>
        <div class="separator">
            <form id='login-email-step' @submit.prevent="submitEmail" v-if="this.formStep === 'email'">
                <div>
                    <label class="input-label" for="login-email">Email address</label>
                    <input
                        v-model="email"
                        class="login-form-input"
                        type="text"
                        id="login-email"
                        name="email"
                    />
                </div>
                <button class="action-button" type="submit">
                    Next
                </button>
            </form>
            <form id='login-password-step' @submit.prevent="authenticate" v-if="this.formStep === 'password'">
                <div>
                    <label class="input-label" for="login-password">Password</label>
                    <input
                        v-model="password"
                        class="login-form-input"
                        type="password"
                        id="login-password"
                        name="password"
                    />
                </div>
                <button class="action-button" type="submit">
                    Sign In
                </button>
                <a class="back-action" @click.prevent="setStep('email')">
                    Go Back
                </a>
                <div id="login-checkbox-container">
                    <div class="login-checkbox">
                        <input
                            type="checkbox"
                            v-model="rememberMe"
                            :true-value="true"
                            :false-value="false">
                        Remember my username
                    </div>
                </div>
            </form>
            <form @submit.prevent="verifyMFA" v-if="this.formStep === 'mfa'">
                <div class="mfa-input-container">
                    <div id="mfa-input-label-container">
                        <label class="input-label" for="mfa">MFA Code</label>
                    </div>
                    <input
                        class="login-form-input-mfa"
                        type="text"
                        id="mfa"
                        v-model="mfaCode"
                        name="mfa"
                        autocomplete="off"
                    />
                </div>
                <button class="action-button" type="submit" @click.prevent="verifyMFA">
                    Verify
                </button>
                <a class="back-action" type="submit" @click.prevent="requestNewCode">
                    Request New Code
                </a>
                <a class="back-action" @click.prevent="setStep('password')">
                    Go Back
                </a>
            </form>
        </div>
        <div class="sub-title-text" v-if="this.formStep === 'password'">
            <a href="/forgot">Forgot password</a>
        </div>
        <div class="sub-title-text">Don't have an account yet?
            <a target="_blank" href="https://info.acclaro.com/my-acclaro-registration">Create Client Account</a>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {getCookie, validateEmail} from "../../common/utils";

export default {
    name: 'login',
    data() {
        return {
            email: "",
            password: "",
            formStep: "email",
            message: "",
            mfaRequired: false,
            mfaCode: '',
            rememberMe: undefined
        };
    },
    mounted() {

        this.email = getCookie('remember') ?? ''
        this.rememberMe = getCookie('remember') !== undefined;
        if((Boolean(new URL(location.href).searchParams.get('session_time_out')))){
            this.message = '<p>Your session has expired and you were signed off automatically.</p> <p>Sign back in to continue working in My Acclaro.</p>'
        }
    },
    methods: {
        submitEmail() {
            if (!validateEmail(this.email)) {
                this.message = 'Please enter a valid email address'
                return
            }

            axios.post(`/login-service`, {
                email: this.email,
                _token: $('input[name="_token"]').val(),
            }).then(({data}) => {
                this.mfaRequired = data.mfa_required
                if (data.success === true) {
                    if (data.data.service) {
                        this.message = "Signing into My Acclaro with " + data.data.service + "...</strong>";
                        window.location.href = data.data.url;
                    } else {
                        this.formStep = 'password'
                        this.message = ''
                    }
                } else {
                    this.message = '<div>Sorry, we didn’t recognize this email address.</div> <div>Check if your email is correct.</div> <div>If the problem persists, contact <a href="mailto:support@acclaro.com">support@acclaro.com</a> for assistance.</div>'
                }
            }).catch(e => {this.message = e});
        },
        authenticate(setMessage = true) {

            if (this.password.length < 8) {
                this.message = 'Password must be at least 8 characters'
                return
            }

            if (this.mfaRequired) {
                axios.get(`/mfa/code?email=${this.email}&password=${this.password}`)
                    .then((response) => {
                        if (response.data.message === "Invalid Password") {
                            this.message = 'Invalid username or password. <br />Please try again. <br />If login ' +
                                'problems persist, please <a href="mailto:support@acclaro.com">contact us</a> for support.';
                        } else {
                            if (setMessage) this.message = `We’ve sent the one-time verification code to ${this.email}.<br /> Code expires in 15 minutes.`
                            this.formStep = 'mfa'
                        }
                    }).catch((err) => {
                    if (err.status === 401) {
                        this.message = 'Invalid username or password. <br />Please try again. <br />If login ' +
                            'problems persist, please <a href="mailto:support@acclaro.com">contact us</a> for support.';
                    } else {
                        this.message = 'There was an error signing you in.<br /> Please try again. <br />If login ' +
                            'problems persist, please <a href="mailto:support@acclaro.com">contact us</a> for support.';
                    }
                });
            } else {
                this.handleAuthentication();
            }
        },
        requestNewCode() {
            this.message = "We've sent you a new MFA code."
            this.authenticate(false)
        },
        verifyMFA() {

            if (this.mfaCode.length !== 6) {
                this.message = 'MFA Code is 6 characters'
                return
            }

            axios.post(`/mfa/verify`, {
                code: this.mfaCode,
                email: this.email
            }).then(({data}) => {
                const {success} = data
                if (success) {
                    this.handleAuthentication();
                } else {
                    this.message = 'That was an invalid code. <br />If login ' +
                        'problems persist, please <a href="mailto:support@acclaro.com">contact us</a> for support.'
                }
            }).catch(() => {
                this.message = "Something went wrong. <br />Please contact <a href=\"mailto:support@acclaro.com\">support@acclaro.com</a> for assistance ";
            });

        },
        handleAuthentication: function () {
            axios.post(`/login`, {
                username: this.email,
                _token: $('input[name="_token"]').val(),
                password: this.password,
                rememberit: this.rememberMe,
            }).then(({data}) => {
                const {success, message, redirect_url} = data
                if (success === true) {
                    window.location = redirect_url
                } else {
                    this.message = message;
                }
            }).catch(() => {
                this.message = "Something went wrong.<br /> Please contact <a href=\"mailto:support@acclaro.com\">support@acclaro.com</a> for assistance ";
            });
        },
        setStep(step) {
            this.formStep = step
        }
    }
}
</script>
